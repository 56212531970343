import styles from './AuthLayouts.module.scss'
import { Logo } from "@/layouts/Logo/Logo"

const AuthLayouts = ({ children }) => {
  return (
    <div className={ styles.root }>
      <div className={ 'margin-v-3' }>
        <Logo className={ 'margin-v-3' }/>
      </div>
      <div className={ styles.container }>
        { children }
      </div>
    </div>
  )
}

export default AuthLayouts
