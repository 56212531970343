import AuthLayouts from "@/layouts/AuthLayouts/AuthLayouts"
import SignInComponent from "@/components/Auth/Signin/SignIn"

const Signin = () => {
  return (
    <AuthLayouts>
      <SignInComponent/>
    </AuthLayouts>
  )
}

export default Signin
