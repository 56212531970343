import styles from './SignIn.module.scss'
import Input from "@/gdeluLib/Input/Input"
import BtnLink from "@/gdeluLib/BtnLink/BtnLink"
import Btn from "@/gdeluLib/Btn/Btn"
import { connect } from 'react-redux'
import { useState } from "react"
import { signIn } from "@/store/user/auth/actions"
import routes from '@/routes/index'
import { useRouter } from "next/router"

const { recovery } = routes.auth

const SignIn = ({ fetch, signIn, startAuth, isError }) => {
  const initialFields = {
    user: '',
    pass: ''
  }
  const router = useRouter()
  const [ fields, setFields ] = useState(JSON.parse(JSON.stringify(initialFields)))
  const { user, pass } = fields
  const changeField = ({ name, value }) => setFields({ ...fields, [ name ]: value })
  const onSigIn = signIn.bind(null, { user, pass, router })
  return (
    <div className={ styles.root }>
      <h1 className={ 'title-h4 margin-v-3' }>Вход</h1>
      <div className={ 'margin-v-4' }>
        <Input keyPress={ onSigIn } name={ 'user' } onChange={ (value, name) => changeField({ value, name }) }
               value={ user }
               className={ styles.input }
               isError={ startAuth && user.length <= 0 || isError } placeholder={ 'Имя пользователя' }/>
      </div>
      <div className={ 'margin-v-4' }>
        <Input type={ 'password' } name={ 'pass' } onChange={ (value, name) => changeField({ value, name }) }
               keyPress={ onSigIn }
               className={ styles.input }
               value={ pass } isError={ startAuth && pass.length <= 0 || isError } placeholder={ 'Пароль' }/>
      </div>
      <BtnLink className={ 'margin-v-3' }
               href={ recovery.href }
               as={ recovery.as }
               color={ 'blue' } title={ 'Не помню пароль' }/>
      <Btn disabled={ fetch } onClick={ onSigIn } className={ styles.btn } title={ 'Войти' } styleBtn={ 'blue' }/>
    </div>
  )
}

const mapStateToProps = state => ({
  fetch: state.user.auth.fetch,
  startAuth: state.user.auth.startAuth,
  isError: state.user.auth.isError,
})

const mapDispatchToProps = dispatch => ({
  signIn: ({ pass, user, router }) => dispatch(signIn({ pass, user, router }))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)
